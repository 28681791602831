import styled from 'styled-components';

const ThemeButton = styled.div`
    top: 5%;
    right: 3%;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #ababab;
    cursor: pointer;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-in-out;
    padding: 5px;

    &:active {
        transform: translateY(-3px);
    }

    & i {
        font-size: 1.4rem;
        color: var(--color-grey-2);
        pointer-events: none;
    }
`;

const IconImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;


export function ThemeControl() {

    // Function to check if the body tag contains a class
    const hasClassName = (className) => {
        return document.body.classList.contains(className);
    };

    const toggleTheme = () => {
        if (hasClassName("light-mode")) {
            document.body.classList.remove('light-mode'); // Replace 'your-class-name' with the desired class name
        } else {
            document.body.classList.add('light-mode');
        };
    }
    

    return (
        <ThemeButton onClick={toggleTheme}>
            <IconImage 
                className="btn-icon"
                src="img/theme.png"
                alt="Home"
                width={100}
                height={100}
            />
        </ThemeButton>
  )
}

export default ThemeControl;
