import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const Controls = styled.div`
    position: fixed;
    z-index: 10;
    top: 50%;
    right: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    text-align: center;

    & .active-btn {
        background-color: var(--color-secondary);
        transition: all 0.4s ease-in-out;
    }

    & .active-btn i {
        color: white;
    }


    @media screen and (max-width: 600px) {
        top: auto;
        bottom: 0;
        flex-direction: row;
        justify-content: center;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: var(--color-grey-5);

        & .control {
            margin: 1rem 0.3rem;
        }
    }
`;

const NavItem = styled(Link)`
    padding: 1rem;
    cursor: pointer;
    background-color: #aeaeae;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.7rem 0;
    box-shadow: var(--box-shadow-1);

    & i {
        font-size: 1.3rem;
        color: #3b3b3b;
        pointer-events: none;
    }
`;

export function Header(props) {
    const location = useLocation();

    // Access the pathname to determine the current route
    const currentRoute = location.pathname;
    return (
        <Controls>
            <NavItem to="/" className={currentRoute === '/' ? "control active-btn" : "control"}>
                <i className="fas fa-home"></i>
            </NavItem>
            <NavItem to="/skills" className={currentRoute === "/skills" ? "control active-btn" : "control"}>
                <i className="fas fa-user"></i>
            </NavItem>
            <NavItem to="/works" className={currentRoute === "/works" ? "control active-btn" : "control"}>
                <i className="fas fa-suitcase"></i>
            </NavItem>
            <NavItem to="/contact" className={currentRoute === "/contact" ? "control active-btn" : "control"}>
                <i className="fas fa-envelope"></i>
                {/* <IconImage 
                    src="img/contact.png"
                    alt="Contact"
                    width={100}
                    height={100}
                /> */}
            </NavItem>
        </Controls>
    )
}

export default Header;
