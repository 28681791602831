import React from 'react';
import styled from 'styled-components';

const ContactBlock = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 20px;
  flex-wrap: wrap; /* Allow items to wrap to the next row */
  justify-content: space-between; /* Evenly distribute items */
  max-width: 100%;
  padding-bottom: 6rem;
`;

const ContactItem = styled.div`
  padding: 20px;
	font-family: "poppins-regular", sans-serif;
  min-width: 300px;
  flex: 1;
	font-size: 1.5rem;
	text-align: center;
  & .collapse {
    padding: 0;
  }
  & .icon {
    margin-bottom: 2.1rem;
  }
  & .icon i {
    font-size: 2.2rem;
    color: #FFFFFF;
  }
  & h5 {
    color: var(--color-secondary);
  }
  & p {
    font-size: 0.6em;
    line-height: 2rem;
    color: var(--color-grey-2);
  }

  @media (max-width: 400px) {
    min-width: 100%;
  } 
  
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
`;

export default function Contact() {
  return (
    <>
      <main>
        <section className="container">
          <CenteredContainer>
            <div className="main-title">
              <h2>Contact <span>Me</span><span className="bg-text">Contact</span></h2>
              <p>
                If you have ambitious ideas or wish to explore business opportunities, please do not hesitate to reach out. Your time is valuable, and I'm eager to collaborate.
              </p>
            </div>
          </CenteredContainer>
          <CenteredContainer>
                <ContactBlock>

                  <ContactItem>

                    <div className="icon">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>

                    <h5>Where to find me</h5>

                    <p>
                      Belgium,<br/>
				              West-Flanders
                    </p>

                  </ContactItem>

                  <ContactItem>

                    <div className="icon">
                      <i className="fa-solid fa-envelope"></i>
                    </div>

                    <h5>Email Me At</h5>

                    <a href="mailto:jonas@vanhastel.com"><p>jonas@vanhastel.com</p></a>

                  </ContactItem>

                  <ContactItem>

                    <div className="icon">
                      <i className="fa-solid fa-hashtag"></i>
                    </div>

                    <h5>Check out socials</h5>
                    <CenteredContainer>
                      <div className="contact-icon">
                          <a href="https://www.facebook.com/vanhasteljonas/" target="_blank" rel="noreferrer">
                              <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="https://www.instagram.com/vanhasteljonas/" target="_blank" rel="noreferrer">
                              <i className="fab fa-instagram"></i>
                          </a>
                          <a href="https://www.linkedin.com/in/vanhasteljonas/" target="_blank" rel="noreferrer">
                              <i className="fab fa-linkedin"></i>
                          </a>
                          <a href="https://github.com/vanhasteljonas/" target="_blank" rel="noreferrer">
                              <i className="fab fa-github"></i>
                          </a>
                      </div>
                    </CenteredContainer>
                  </ContactItem>

                </ContactBlock>
              </CenteredContainer>
        </section>
      </main>
    </>
  )
}
