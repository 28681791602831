import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/styles.css";
import Works from './pages/Works/Home';
// import WorksCaptain from './pages/Works/Captain';
// import WorksCoaching from './pages/Works/Coaching';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Skills from './pages/Skills';

import Header from './components/Header';
import ThemeControl from './components/ThemeControl';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/works" element={<Works />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/works/coaching" element={<WorksCoaching />} />
        <Route path="/works/captain" element={<WorksCaptain />} /> */}
        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
      <Header />
      <ThemeControl />
    </BrowserRouter>
  );
}
