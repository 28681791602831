import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React from 'react';

const Headers = styled.header`
  min-height: 100vh;
  color: var(--color-white);
  overflow: hidden;
  padding: 0 !important;
  
  @media screen and (max-width: 700px) {
      padding: 7rem 3rem;
  }
`;

export default function Home() {
  return (
    <>
      <Headers>
        <div className="header-content">
          <div className="left-header">
            <div className="h-shape"></div>
            <div className="image">
              <img src="/img/jonas.JPG" alt="Jonas Van Hastel" />
            </div>
          </div>
          <div className="right-header">
            <h1 className="name">
              Hey, I'm <span>Jonas Van Hastel.</span>
            </h1>
            <p>
              I'm on an unrelenting journey to master every field I venture into, driven by a deep commitment to not only excel but also to uplift and empower those around me. My ultimate mission is to transform our world into a more beautiful and harmonious place. With unwavering dedication, I give my all, always going that extra mile, and I'm recognized for my openness and eagerness to extend a helping hand wherever it's needed.
            </p>
            <div className="btn-con">
              <Link 
                to="/works" 
                className="main-btn"
              >
                <span className="btn-text">Companies</span>
                <span className="btn-icon"><i className="fas fa-suitcase"></i></span>
              </Link>
            </div>
          </div>
        </div>
      </Headers>
    </>
  )
}
